<template>
  <div style="margin-top: 15px;width: 1200px; display: flex">
    <!--  左侧栏  -->
    <el-scrollbar
        style="height: 100%; width: 119px"
        wrap-style="overflow-x:hidden;"
    >
      <el-menu
          :default-active="activeIndex"
          :collapse="false"
          :unique-opened="true"
      >
        <template v-for="item in items">
          <!-- 第一层 不含子菜单  -->
          <el-menu-item
              :index="item.code"
              @click="selectItem(item)"
              :key="item.code"
          >
            <i v-show="item.icon" :class="item.icon"></i>
            <!--            <img-->
            <!--                class="content-left"-->
            <!--                style="width: 15px;height: 15px;"-->
            <!--                src="../../../assets/loginout.png"-->
            <!--                alt="LOGO"-->
            <!--            />-->
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
    <!-- 会员信息 -->
    <member-info v-if="activeIndex === 0"></member-info>
    <change-password v-if="activeIndex === 1"></change-password>
    <down-statistics v-if="activeIndex === 2"></down-statistics>
    <leave-message v-if="activeIndex === 3"></leave-message>
    <principal-application v-if="activeIndex === 4"></principal-application>
    <shop-buy-info v-if="activeIndex === 5"></shop-buy-info>
    <to-upload v-if="activeIndex === 6"></to-upload>
  </div>
</template>
<script>
import ChangePassword from "./components/changePassword.vue";
import DownStatistics from "./components/downStatistics.vue";
import LeaveMessage from "./components/leaveMessage.vue";
import MemberInfo from "./components/memberInfo.vue";
import PrincipalApplication from "./components/principalApplication.vue";
import ShopBuyInfo from "./components/shop/index";
import ToUpload from "./components/toUpload.vue";
import {updateVisitorNumber} from "../../../api/user";


export default {
  components: {
    MemberInfo,
    ChangePassword,
    DownStatistics,
    LeaveMessage,
    PrincipalApplication,
    ShopBuyInfo,
    ToUpload,
  },
  data() {
    return {
      activeIndex: 0,
      items: [
        {
          code: 0,
          name: "会员信息",
          icon: "el-icon-user",
        },
        {
          code: 1,
          name: "修改密码",
          icon: "el-icon-edit",
        },
        {
          code: 2,
          name: "下载统计",
          icon: "el-icon-pie-chart",
        },
        {
          code: 3,
          name: "我要留言",
          icon: "el-icon-s-comment",
        },
        {
          code: 4,
          name: "校长申请",
          icon: "el-icon-reading",
        },
        {
          code: 5,
          name: "我的购买",
          icon: "el-icon-shopping-bag-1",
        },
        {
          code: 6,
          name: "我要上传",
          icon: "el-icon-upload",
        },
        {
          code: 7,
          name: "退出系统",
          icon: "el-icon-switch-button",
        },
      ],
    };
  },
  methods: {
    selectItem(item) {
      this.activeIndex = item.code;
      if (this.activeIndex === 7) {
        this.loginOut();
      }
    },
    loginOut() {
      updateVisitorNumber().then(res => {
        this.$store.dispatch("LogOut").then(() => {
          this.$router.push({path: "/"});
        });
      })
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-menu-item {
    height: 45px;
    line-height: 45px;
    padding: 10px 20px;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-menu-item.is-active {
    color: #fff;
    background: #0080ff;
  }

  .el-scrollbar__wrap {
    margin-bottom: 0 !important;
  }
}

.box {
  background: #fff;
  margin-left: 10px;
  padding: 21px 55px;
  width: calc(100% - 119px - 10px);
}
</style>
