<template>
  <div class="box">
    <h2 style="margin: 0; color: #333; font-weight: 400">校长申请</h2>
    <div>
      <div class="apply" v-if="userInfo.sysMemberUser.principal == '0'">
        <p>校长状态： 我还不是校长，</p>
        <el-link type="primary" @click="applyClick()">申请</el-link>
        <p style="margin-left: 2px">成为校长。</p>
      </div>
      <div class="apply" v-else>
        <p>我已是校长</p>
      </div>
      <el-table
        :data="tableData"
        border
        :cell-style="{ borderColor: '#4DA3F9' }"
        :header-cell-style="{
          background: '#EDF8FF',
          color: '#333333',
          fontWeight: '400',
          borderColor: '#4DA3F9',
        }"
        style="width: 100%; border: 1px solid #4da3f9"
      >
        <el-table-column prop="createTime" label="申请时间" align="center">
        </el-table-column>
        <el-table-column label="审核状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.status == '0'">待审核</div>
            <div v-if="scope.row.status == '1'">已审核</div>
            <div v-if="scope.row.status == '2'">终止审核</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="审核批复时间"
          align="center"
        ></el-table-column>
        <el-table-column prop="remark" label="批复内容" align="center">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { findPrincipal, userApplyPrincipal } from "../../../../api/front/home";
import {getUserInfo} from "../../../../api/login";
export default {
  data() {
    return {
      tableData: [],
      userInfo:{}
    };
  },
  mounted() {
    findPrincipal().then((res) => {
      this.tableData = res.data.data;
    });
    getUserInfo().then(res => {
      this.userInfo = res.data.data;
    })
  },
  methods: {
    applyClick() {
      userApplyPrincipal().then((res) => {
        this.$message.success("申请成功");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.apply {
  display: flex;
  margin: 9px 0;
}
</style>
