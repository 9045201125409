<template>
  <div class="box">
    <h2 style="margin: 0; color: #333; font-weight: 400">基础信息</h2>
    <div>
      <h2 style="margin: 13px 0 15px 0; color: #333; font-weight: 400">
        {{ userInfo.sysMemberUser.name }}
      </h2>
      <el-form
          ref="form"
          size="mini"
          :inline="true"
          :model="filterData"
          label-suffix=":"
      >
        <el-row>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="真实姓名">
              <div>{{ userInfo.sysMemberUser.realName }}</div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="16" :lg="16" :xl="16">
            <el-form-item label="执教年级">
              <div>{{ userInfo.sysMemberUser.teachingGrade }}</div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="执教学科">
              <div>{{ userInfo.sysMemberUser.teachingDiscipline }}</div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="执教地区">
              <div>{{ userInfo.sysMemberUser.teachingArea }}</div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="执教单位">
              <div>{{ userInfo.sysMemberUser.teachingUnit }}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div>
      <h3 style="margin: 26px 0 15px 0; color: #333; font-weight: 400">
        系统信息
      </h3>
      <el-form
          ref="form"
          size="mini"
          :inline="true"
          :model="filterData"
          label-suffix=":"
      >
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="会员级别">
              <div v-if="userInfo.sysMemberRand.name">
                {{ userInfo.sysMemberRand.name }}
              </div>
              <div v-else>--</div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="每日可下载次数">
              <div>{{ userInfo.sysMemberUser.downloadNumber }}</div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="注册时间">
              <div>{{ userInfo.sysMemberUser.registerDate }}</div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="16" :lg="8" :xl="6">
            <el-form-item label="本次登录时间">
              <div>{{ userInfo.sysMemberUserInformation.loginThisTime }}</div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="16" :lg="8" :xl="6">
            <el-form-item label="最近登录时间">
              <div>{{ userInfo.sysMemberUserInformation.loginLastTime }}</div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="本次登录IP">
              <div>{{ userInfo.sysMemberUserInformation.loginThisIp }}</div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="最近一次登录IP">
              <div>{{ userInfo.sysMemberUserInformation.loginLastIp }}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div>
      <h3 style="margin: 26px 0 15px 0; color: #333; font-weight: 400">
        账户信息
      </h3>
      <el-form
          ref="form"
          size="mini"
          :inline="true"
          :model="filterData"
          label-suffix=":"
      >
        <el-row>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="有效截至时间">
              <div v-if="userInfo.sysMemberUser.validityPeriod">
                {{ userInfo.sysMemberUser.validityPeriod }}
              </div>
              <div v-else>--</div>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="登录次数">
              <div>
                {{ userInfo.sysMemberUser.loginCount }}次
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import {getUserInfo} from "../../../../api/login";

export default {
  data() {
    return {
      levelName: "",
      filterData: {},
      userInfo: {}
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      getUserInfo().then((res) => {
        this.userInfo = res.data.data;
      });
    },

  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-form-item__label {
    color: #999;
    font-size: 13px;
  }

  .el-form-item__content {
    color: #333;
    font-size: 13px;
  }
}
</style>
