<template>
  <div class="box">
    <h2 style="margin: 0; color: #333; font-weight: 400">下载统计</h2>
    <div class="table">
      <el-table
        :data="tableData"
        border
        :cell-style="{ borderColor: '#4DA3F9' }"
        :header-cell-style="{
          background: '#EDF8FF',
          color: '#333333',
          fontWeight: '400',
          borderColor: '#4DA3F9',
        }"
        style="width: 100%; border: 1px solid #4da3f9"
      >
        <el-table-column prop="memberUserName" label="用户名" align="center">
        </el-table-column>
        <el-table-column prop="documentName" label="资源名称" align="center">
        </el-table-column>
        <el-table-column label="消费方式" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.type == '1'">有效期类型</div>
            <div v-if="scope.row.type == '2'">充扣点类型</div>
          </template>
        </el-table-column>
        <el-table-column prop="downloadDate" label="下载时间" align="center">
        </el-table-column>
      </el-table>
      <div class="center">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchMemberPage } from "../../../../api/front/home";
export default {
  data() {
    return {
      total: 0,
      pageSize: 10,
      currentPage: 1,
      tableData: [],
    };
  },
  mounted() {
    this.getMemberList();
  },
  methods: {
    getMemberList() {
      fetchMemberPage(
        Object.assign({
          current: this.currentPage,
          size: this.pageSize,
        })
      ).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getMemberList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getMemberList();
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 13px;
}
.center {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>