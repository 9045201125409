<template>
  <div class="box">
    <h2 style="margin: 0; color: #333; font-weight: 400">修改密码</h2>

    <div class="form">
      <el-form ref="form" :rules="rules" :model="filterData" label-width="0px">
        <el-form-item>
          <el-input v-model="filterData.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              placeholder="请输入原始密码"
              v-model="filterData.password"
              show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input
              placeholder="请输入新的密码"
              v-model="filterData.newPassword"
              show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="checkNewPassword">
          <el-input
              placeholder="请再输入一次"
              v-model="filterData.checkNewPassword"
              show-password
          >
          </el-input>
        </el-form-item>
        <el-button
            :loading="loadingButton"
            type="primary"
            class="btn"
            @click="submit()"
        >
          修改密码
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import {updatePassword} from "../../../../api/front/home";
import {getUserInfo} from "../../../../api/login";

export default {
  data() {
    let checkPwd = (rule, value, callback) => {
      if (!this.filterData.checkNewPassword) {
        callback(new Error("请再次输入密码"));
      } else if (value != this.filterData.newPassword) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      loadingButton: false,
      filterData: {
        name:''
      },
      rules: {
        password: [
          {required: true, message: "请输入原始密码", trigger: "blur"},
          {
            min: 6,
            message: "密码不得少于6位",
            trigger: "blur",
          },
        ],
        newPassword: [
          {required: true, message: "请输入新的密码", trigger: "blur"},
          {
            min: 6,
            message: "密码不得少于6位",
            trigger: "blur",
          },
        ],
        checkNewPassword: [
          {required: true, validator: checkPwd, trigger: "blur"},
          {
            min: 6,
            message: "密码不得少于6位",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    getUserInfo().then(res => {
      this.filterData.name = res.data.data.sysMemberUser.name;
    })
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          updatePassword({
            password: this.filterData.password,
            newPassword: this.filterData.newPassword,
            checkNewPassword: this.filterData.checkNewPassword,
          })
              .then((res) => {
                this.$message.success("修改成功");
                setTimeout(() => {
                  this.$router.replace("/login");
                }, 1000);
              })
              .catch(() => {
                this.loadingButton = false;
              });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form {
    width: 347px;
  }

  .el-input--small .el-input__inner {
    height: 47px;
    line-height: 47px;
  }
}

.form {
  display: flex;
  justify-content: center;
  margin-top: 13px;
}

.btn {
  width: 100%;
  margin-bottom: 23px;
  background: #0080ff;
  height: 47px;
  font-size: 16px;
}
</style>
