<template>
  <div class="box">
    <h2 style="margin: 0; color: #333; font-weight: 400">我要上传</h2>
    <div class="text">
      请将您的资料发到邮箱{{ email }}，或者加{{ qq }}
      在线传输给我们，谢谢！
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      qq: "",
    };
  },
  mounted() {
    this.qq = sessionStorage.getItem("qq");
    this.email = sessionStorage.getItem("email");
  },
};
</script>

<style lang="scss" scoped>
.text {
  font-size: 13px;
  color: #0080ff;
  margin-top: 13px;
}
</style>