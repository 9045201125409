<template>
  <div class="box">
    <el-tabs v-model="activeName" @tab-click="getMessageList">
      <el-tab-pane label="我要留言" name="first"></el-tab-pane>
      <el-tab-pane label="会员留言"    name="second"></el-tab-pane>
    </el-tabs>
    <div v-if="activeName == 'first'" style="margin-top: 13px">
      <el-input
        v-model="content"
        type="textarea"
        :autosize="{ minRows: 12, maxRows: 30 }"
        placeholder="请输入您的留言"
      ></el-input>
      <el-button
        type="primary"
        size="mini"
        class="btn"
        :disabled="!content"
        @click="submit()"
        >提交</el-button
      >
    </div>
    <div v-if="activeName == 'second'">
      <div
        v-for="(item, index) in infoList"
        :key="index"
        style="border-bottom: 1px solid #d8d8d8; padding-bottom: 13px"
      >
        <h3 style="color: #333; font-weight: 400">您的留言</h3>
        <div class="rows">
          <p class="text">{{ item.sysLeaveMessage.content }}</p>
          <p class="date">{{ item.sysLeaveMessage.createTime }} 发表</p>
        </div>
        <div class="leave">
          <div class="message">
            <span v-if="item.sysLeaveMessageReplies">管理员留言:</span>
            <div>
              <div
                v-for="(key, index1) in item.sysLeaveMessageReplies"
                :key="index1"
                style="margin-bottom: 4px"
                class="rows"
              >
                <p style="flex: 1">
                  {{ key.content }}
                </p>
                <p class="date" style="font-size: 12px; margin-left: 24px">
                  {{ key.createTime }} 发表
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { userMessage, cardMessage } from "../../../../api/front/home";
export default {
  data() {
    return {
      total: 0,
      pageSize: 10,
      currentPage: 1,
      activeName: "first",
      content: "",
      infoList: [],
    };
  },
  mounted() {
    this.getMessageList();
  },
  methods: {
    getMessageList() {
      cardMessage(
        Object.assign({
          current: this.currentPage,
          size: this.pageSize,
        })
      ).then((res) => {
        this.infoList = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getMessageList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getMessageList();
    },
    submit() {
      userMessage({ content: this.content }).then((res) => {
        this.$message.success("留言成功");
        this.content = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-tabs__item {
    font-size: 18px;
    color: #666;
  }
  .el-tabs__item.is-active {
    color: #333;
  }
}
.center {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
p {
  margin: 0;
}
.leave {
  padding-left: 47px;
  margin-top: 18px;
  .info {
    font-size: 12px;
    color: #666;
  }
  .message {
    display: flex;
    span {
      width: 90px;
      color: #0080ff;
      font-size: 15px;
    }
    div {
      flex: 1;
      color: #333333;
    }
  }
}
.rows {
  display: flex;
  justify-content: space-between;
  .text {
    color: #333;
  }
  .date {
    color: #666666;
  }
}
.btn {
  float: right;
  margin-top: 13px;
  background: #0080ff;
}
</style>