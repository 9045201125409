<template>
  <div>
    <el-table
        :data="tableData"
        border
        :cell-style="{ borderColor: '#4DA3F9' }"
        :header-cell-style="{
          background: '#EDF8FF',
          color: '#333333',
          fontWeight: '400',
          borderColor: '#4DA3F9',
        }"
        style="width: 100%; border: 1px solid #4da3f9"
    >
      <el-table-column
          prop="name"
          label="商品名称"
          align="center"
      >
      </el-table-column>

      <el-table-column label="商品类型" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.type === '1'">有效期类型</div>
          <div v-if="scope.row.type === '2'">充扣点类型</div>
        </template>
      </el-table-column>

      <el-table-column
          prop="description"
          label="商品描述"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="price"
          label="商品价格(元)"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="address"
          align="center"
          label="操作">
        <template scope="scope">
          <el-button type="text" @click="buyShop(scope.row)">购买</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog v-if="dialogScanCode"
               title="购买课程"
               @close="closeDialogPay"
               :close-on-click-modal="false"
               :visible.sync="dialogScanCode"
               width="400px">
      <div class="tanp">
        <div style="width: 300px;margin: 0 auto;text-align: center">

          <p style="text-align: center;">课程价格：{{ orderRow.price }}元</p>

          <div>
            <img style="width: 200px;height: 200px"
                 :src="'data:image/png;base64,'+this.result.codeUrl"/>
            <div style="text-align: center;font-weight: 900">请使用微信扫码支付</div>
            <div style="text-align: center;font-weight: 1000;color: #0080FF">支付完成后请点击我已付款按钮哦</div>
          </div>

        </div>

      </div>
      <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="createOrderNo">我已付款</el-button>
            <el-button @click="closeDialogPay">关 闭</el-button>
            </span>
    </el-dialog>


  </div>
</template>

<script>
import {findProduct} from "../../../../../api/front/home";
import {checkCreatOrder, getOrderDetail} from "../../../../../api/user";

export default {
  name: "shopInfo",
  data() {
    return {
      tableData: [],
      orderRow: {},
      result: {
        // 订单号
        orderNo: '',
        // 二维码地址
        codeUrl: ''
      },
      dialogScanCode: false,
      timer: null
    }

  },
  methods: {
    buyShop(row) {
      const loading = this.$loading({
        lock: true,
        text: '创建订单中,请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.orderRow = row;
      this.getOrder(loading);
    },
    // 关闭窗口回调
    closeDialogPay() {
      this.$confirm('您确定关闭支付窗口嘛?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dialogScanCode = false;
        this.result = {};
      }).catch(() => {
      })
    },

    // 校验支付状态
    createOrderNo() {
      const loadingComponent = this.$loading({
        lock: true,
        text: '正在校验,请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      checkCreatOrder({orderNo: this.result.orderNo}).then(res => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg);
          this.dialogScanCode = false;
          this.result = {};
        } else {
          this.$message.error(res.data.msg)
        }
      })
      loadingComponent.close();
    },

    getOrder(loading) {
      getOrderDetail({id: this.orderRow.id}).then(res => {
        if (res.data.code === 0) {
          this.result = res.data.data;
          this.dialogScanCode = true;
        } else {
          this.$message.error(res.data.msg)
        }
        loading.close();
      })
    },

    getProList() {
      findProduct().then(res => {
        this.tableData = res.data.data;
      })

    }

  },
  created() {
    this.getProList();
  }
}
</script>

<style scoped>

</style>
