<template>
  <div>
    <div style="display: flex;padding: 10px 0px;">
      <div style="margin-top: 5px;width: 70px">支付状态</div>
      <div style="width: 100px">
        <el-select @change="getBuyList" v-model="queryForm.orderStatus" placeholder="请选择">
          <el-option
              label="未支付"
              value="2">
          </el-option>
          <el-option
              label="已付款"
              value="1">
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 5px;margin-left : 20px;width: 370px;color:#4DA3F9;">
        <span v-if="validityPeriod">普通账户:有效期至 {{ this.validityPeriod }} 友情提示</span>
        <span v-else>普通账户 暂无有效期 友情提示</span>
      </div>

    </div>
    <el-table
        :data="tableData"
        border
        :cell-style="{ borderColor: '#4DA3F9' }"
        :header-cell-style="{
          background: '#EDF8FF',
          color: '#333333',
          fontWeight: '400',
          borderColor: '#4DA3F9',
        }"
        style="width: 100%; border: 1px solid #4da3f9"
    >
      <el-table-column
          prop="orderNo"
          align="center"
          label="订单编号"
          width="150px"
      >
      </el-table-column>
      <el-table-column
          prop="productName"
          align="center"
          label="商品名称"
      >
      </el-table-column>
      <el-table-column
          prop="orderType"
          align="center"
          label="商品类型"
      >
        <template scope="scope">
          <div>
            <!--            orderType：商品类型 [1有效期类型 2充扣点类型]-->

            <span v-if="scope.row.orderType==='1'">有效期类型</span>
            <span v-if="scope.row.orderType==='2'">充扣点类型</span>
          </div>

        </template>
      </el-table-column>
      <el-table-column
          prop="price"
          align="center"
          label="商品价格"
      >
      </el-table-column>
      <el-table-column
          align="center"
          prop="orderStatus"
          label="订单状态"
      >
        <template scope="scope">
          <div>
            <span v-if="scope.row.orderStatus==='1'">已付款</span>
            <span v-if="scope.row.orderStatus==='2'">未付款</span>
          </div>

        </template>
      </el-table-column>


      <el-table-column
          align="center"
          prop="buyName"
          label="购买者"
      >
      </el-table-column>

      <el-table-column
          align="center"
          prop="createTime"
          label="订单时间"
      >
      </el-table-column>
      <el-table-column
          align="center"
          prop="useStatus"
          label="使用状态">

        <template scope="scope">
          <div>
            <span v-if="scope.row.useStatus==='1'">已使用 </span>
            <span v-if="scope.row.useStatus==='2'">未使用</span>
          </div>

        </template>
      </el-table-column>

      <el-table-column
          align="center"
          prop="useStatus"
          label="操作">

        <template scope="scope">
          <div>
            <el-link
                type="primary"
                v-if="scope.row.orderStatus === '1' && scope.row.useStatus === '2'"
                @click="useOrderDate(scope.row)">使用</el-link>
          </div>
        </template>
      </el-table-column>

    </el-table>
    <div class="center">
      <el-pagination
          class="center"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next, jumper"
          :page-sizes="queryForm.size"
          :current-page="queryForm.current"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import {getUserBugById, useOrder} from "../../../../../api/user";

export default {
  name: "bug",
  methods: {
    // 使用订单
    useOrderDate(row) {
      useOrder({orderNo: row.orderNo}).then(res => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg);
          this.getBuyList();
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getBuyList() {
      getUserBugById(this.queryForm).then(res => {
        this.tableData = res.data.data.records[0].sysOrders;
        this.validityPeriod = res.data.data.records[0].validityPeriod;
        this.queryForm.size = res.data.data.size;
        this.queryForm.current = res.data.data.current;
        this.total = res.data.data.total;
      })
    },
    handleSizeChange(val) {
      this.queryForm.size = val;
      this.queryForm.current = 1;
      this.getBuyList();
    },
    handleCurrentChange(val) {
      this.queryForm.current = val;
      this.getBuyList();
    },
  },
  mounted() {
    this.getBuyList();
  },
  data() {
    return {
      queryForm: {
        size: 10,
        current: 1,
        orderStatus: "1"
      },
      total: 10,
      tableData: [],
      validityPeriod: ''
    }

  }
}
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;

}
</style>
