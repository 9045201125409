<template>
  <div
      style="
      background: #fff;
      margin-left: 10px;
      padding: 21px 55px;
      width: calc(100% - 119px - 10px);
    ">
    <el-tabs v-model="activeIndex">
      <el-tab-pane label="商城" name="1"></el-tab-pane>
      <el-tab-pane label="我的购买" name="2"></el-tab-pane>
    </el-tabs>

    <shop-info v-if="activeIndex==='1'"></shop-info>
    <buy-info v-if="activeIndex==='2'"></buy-info>

  </div>


</template>

<script>
import BuyInfo from './buyInfo';
import ShopInfo from './shopInfo';

export default {

  name: "index",
  components: {
    BuyInfo,
    ShopInfo

  },
  data() {

    return {
      activeIndex: '1'

    }

  },
  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = key;


    }

  }
}
</script>

<style scoped>

</style>
